import React from "react"
import ButtonWrapper from "../components/Button"
import { Result } from "antd"

const NotFoundPage = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<ButtonWrapper text="Back Home" cta="/" />}
  />
)

export default NotFoundPage
